import { template as template_08d676a0f5af4dd28365816f1c0f7fe9 } from "@ember/template-compiler";
const FKLabel = template_08d676a0f5af4dd28365816f1c0f7fe9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
