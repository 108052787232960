import { template as template_79da6a1fe5dd4316991f4c82aac5ecfa } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_79da6a1fe5dd4316991f4c82aac5ecfa(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
