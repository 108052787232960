import { template as template_32de0a20b88d419cbdfe387f38d722d1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_32de0a20b88d419cbdfe387f38d722d1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
